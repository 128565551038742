import React, { useState, useEffect, useRef } from "react";

import { graphql, Link } from "gatsby"; 

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { BackgroundImage } from "react-image-and-background-image-fade";

import Closeups from './closeups';
import DetailsText from './details-text';
import { StyledTooltip } from '../tooltip';

/* Carousel Actions */
import { IconContext } from "@react-icons/all-files";
import { FaLongArrowAltLeft } from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import { FaLongArrowAltRight } from "@react-icons/all-files/fa/FaLongArrowAltRight";
import { MdFullscreen } from "@react-icons/all-files/md/MdFullscreen";
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft";
import { IoPlay } from "@react-icons/all-files/io5/IoPlay";
import { IoPause } from "@react-icons/all-files/io5/IoPause";
import { BsBoxArrowInUpRight } from "@react-icons/all-files/bs/BsBoxArrowInUpRight";

import "../../styles/carousel.scss";

import styles from "../../styles/portfolioDetails.module.scss";
import labelStyles from "../../styles/label.module.scss";

export default function Template(props) {
  /* From Contentful */
  const project = props.data.contentfulProject;
  const { previous, next } = props.pageContext;

  /* TODO -- REMOVE after all content is published */
  // const hasDetails = 
  //   !!project.description || !!project.role || !!project.team || !!project.technologies || !!project.links;

  /* CAROUSEL */
  const [ play, setPlay ] = useState(true);

  /* LIGHTBOX */
  const [ open, setOpen ] = useState(false);

  /* Populate Images */
  const IMAGES = !project.imageLinks
    ? [ `${process.env.GATSBY_ASSETS_URL}/no-image.png` ]
    : project.imageLinks.map(image => `${process.env.GATSBY_ASSETS_URL}/${project.slug}/${image}`);
  const IMAGES_LENGTH = IMAGES.length;

  const sliderBackButton = useRef(null);
  const sliderNextButton = useRef(null);

  function handleKeyDown(e) {
    if (e.code === "ArrowLeft" && sliderBackButton.current.instance) {
      sliderBackButton.current.instance.handleOnClick();
    } else if (e.code === "ArrowRight" && sliderNextButton.current.instance) {
      sliderNextButton.current.instance.handleOnClick();
    }
  }

  useEffect(() => {
    /* Reference: https://stackoverflow.com/questions/55565444/how-to-register-event-with-useeffect-hooks */
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [play, open]);

  

  return (
    <div>
      <Link to="/portfolio" className={`${styles.back__link} link`}>
        <IconContext.Provider value={{ size: "24px" }}>
          <MdKeyboardArrowLeft className={`${styles.back__link__icon} icon__1px__down`} />
        </IconContext.Provider>
        <span>Back to <b>Portfolio</b></span>
      </Link>

      <div className={styles.header__container}>
        <h1 className={ `${styles.header} header` }>
          { project.title }
        </h1>
        { project.demoLink
          ?
            <a href={ project.demoLink }
              className={`${styles.header__link} link`}
              target="_blank"
              rel="noopener noreferrer">
              <IconContext.Provider value={{ size: "18px" }}>
                <BsBoxArrowInUpRight className={`${styles.header__link__icon} icon__2px__down`}/>
              </IconContext.Provider>
              { project.demoLink }
            </a>
          : null
        }
      </div>

      {/* Tags */}
      <div>
        { project.tags.map(tag => <div className={ labelStyles.label } key={tag}>{tag}</div>) }
      </div>

      {/* Carousel */}
      <CarouselProvider
        naturalSlideWidth={8}
        naturalSlideHeight={5}
        totalSlides={IMAGES_LENGTH}
        orientation={ "horizontal" }
        isPlaying={ play && !open }
        infinite
        className={ styles.carousel__container }
      >
        <Slider>
          { IMAGES.map((image, i) => {
            return <Slide index={i} key={ i } onClick={ () => setOpen(true) }>
              <BackgroundImage
                alt={ `${project.title} - Slide ${i}` }
                src={ image }
                wrapperClassName={ styles.image__container }
                width="100%"
                height="100%"
                lazyLoad
              />
            </Slide>
            })}
        </Slider>

        {/* LightBox (Close-ups) */}
        <Closeups open={ open } images={ IMAGES } numImages={ IMAGES_LENGTH } onClose={ () => setOpen(false) } />

        {/* Carousel Actions */}
        <div className={ styles.carousel__actions}>
          <div className={ styles.carousel__actions__flex }>
            <ButtonBack ref={sliderBackButton} className={ styles.button__icon } onClick={ () => setPlay(false) }>
              <IconContext.Provider value={{ color: '#FFD29C', size: '24px' }}>
                <FaLongArrowAltLeft />
              </IconContext.Provider>
            </ButtonBack>

            <div className={ styles.carousel__dots }>
              { IMAGES.map((image, i) => {
                return <Dot key={i} slide={i} onClick={ () => setPlay(false) }></Dot>
              })}
            </div>

            <ButtonNext ref={sliderNextButton} className={ styles.button__icon } onClick={ () => setPlay(false) }>
              <IconContext.Provider value={{ color: '#FFD29C', size: '24px' }}>
                <FaLongArrowAltRight />
              </IconContext.Provider>
            </ButtonNext>
          </div>

          {/* Lightbox Control */}
          <div className={ `${ styles.carousel__actions__flex } ${ styles.carousel__actions__top }` }>
            <StyledTooltip title={ `${ play ? 'Pause' : 'Start' } Auto-play` }>
              { play 
                ? 
                <button className={ styles.button__icon } onClick={ () => setPlay(false) }>
                  <IconContext.Provider value={{ color: '#FFD29C', size: '24px' }}>
                    <IoPause />
                  </IconContext.Provider>
                </button>
                :
                <ButtonPlay className={ styles.button__icon } onClick={ () => setPlay(true) }>
                  <IconContext.Provider value={{ color: '#FFD29C', size: '24px' }}>
                    <IoPlay />
                  </IconContext.Provider>
                </ButtonPlay>
              }
            </StyledTooltip>

            <StyledTooltip title="Fullscreen">
              <button className={ styles.button__icon } onClick={ () => setOpen(true) }>
                <IconContext.Provider value={{ color: '#FFD29C', size: '24px' }}>
                  <MdFullscreen />
                </IconContext.Provider>
              </button>
            </StyledTooltip>
          </div>
        </div>
      </CarouselProvider>

      <DetailsText
        description={project.description}
        role={project.role}
        team={project.team}
        technologies={project.technologies}
        links={project.links} />
      
      {/* SLUGS */}
      <div className={styles.footer}>
        { previous 
          ? 
          <div className={`${styles.footer__item} ${styles.footer__item__left}`}>
            <div className={`${styles.footer__label} label`}>
              PREVIOUS
            </div>
            <Link to={`/portfolio/${previous.slug}`} className={`${styles.footer__link} link`}>
              <IconContext.Provider value={{ size: '18px' }}>
                <FaLongArrowAltLeft className="icon__left"/>
              </IconContext.Provider>
              { previous.title }
            </Link>
          </div>
          : null
        }

        { next
          ? 
          <div className={`${styles.footer__item} ${styles.footer__item__right}`}>
            <div className={`${styles.footer__label} label`}>
              NEXT
            </div>
            <Link to={`/portfolio/${next.slug}`} className={`${styles.footer__link} ${styles.footer__link__right} link`}>
              { next.title }
              <IconContext.Provider value={{ size: '18px' }}>
                <FaLongArrowAltRight className="icon__right"/>
              </IconContext.Provider>
            </Link>
          </div>
          : null
        }

      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query contentfulProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      demoLink
      slug
      tags
      description {
        raw
      }
      role {
        raw
      }
      team {
        raw
      }
      technologies {
        raw
      }
      links {
        raw
      }
      imageLinks
    }
  }
`;