import React from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styles from "../../styles/portfolioText.module.scss";

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return <a className="link" href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>;
    }
  },
};

export default function DetailsText(props) {
  const EXISTS = {
    description: !!props.description,
    role: !!props.role,
    team: !!props.team,
    technologies: !!props.technologies,
    links: !!props.links
  };

  return (
    <div className={ styles.details__text }>
      <div className={ styles.header }>
        <button className={ `link ${styles.header__link}` } onClick={() => scrollTo('#description')}>Description</button>
        { EXISTS.team ? <button className={ `link ${styles.header__link}` } onClick={() => scrollTo('#team')}>Team</button> : null }
        { EXISTS.role ? <button className={ `link ${styles.header__link}` } onClick={() => scrollTo('#role')}>Role</button> : null }
        { EXISTS.technologies ? <button className={ `link ${styles.header__link}` } onClick={() => scrollTo('#technologies')}>Technologies</button> : null }
        { EXISTS.links ? <button className={ `link ${styles.header__link}` } onClick={() => scrollTo('#links')}>Links</button> : null }
      </div>

      <div className={ styles.body }>
        {/* DESCRIPTION */}
        { EXISTS.description
          ? 
          <div id="description" className={ styles.section }>
            <div className={ styles.label }>Description</div>
            { renderRichText(props.description, options)}
          </div>
          : 
          <div id="description" className={ styles.section }>
            <div className={ styles.label }>Description</div>
            <p><b>This is a work in progress &mdash; stay tuned!</b></p>
          </div>
        } 

        {/* TEAM */}
        { EXISTS.team
          ? 
          <div id="team" className={ styles.section }>
            <div className={ styles.label }>Team</div>
            { renderRichText(props.team, options)}
          </div>
          : null
        }

        {/* ROLE */}
        { EXISTS.role
          ?
          <div id="role" className={ styles.section }>
            <div className={ styles.label }>Role</div>
            { renderRichText(props.role, options)}
          </div>
          : null
        }

        {/* TECHNOLOGIES */}
        { EXISTS.technologies
          ?
          <div id="technologies" className={ styles.section }>
            <div className={ styles.label }>Technologies</div>
            { renderRichText(props.technologies, options)}
          </div>
          : null
        }

        {/* LINKS */}
        { EXISTS.links
          ?
          <div id="links" className={ styles.section }>
            <div className={ styles.label }>Links</div>
            { renderRichText(props.links, options)}
          </div>
          : null
        }
      </div>
    </div>
  );
};