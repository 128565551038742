import React, { useContext, useEffect, useState } from 'react';
import { CarouselContext } from 'pure-react-carousel';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function Closeups(props) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, currentSlide]);

  return (
    <div>
      {
        props.open ?
          <Lightbox
            mainSrc={ props.images[currentSlide] }
            nextSrc={ props.images[(currentSlide + 1) % props.numImages] }
            prevSrc={props.images[(currentSlide + props.numImages - 1) % props.numImages]}
            onCloseRequest={() => props.onClose() }
            onMovePrevRequest={ () => setCurrentSlide((currentSlide + props.numImages - 1) % props.numImages ) }
            onMoveNextRequest={ () => setCurrentSlide((currentSlide + 1) % props.numImages) }
          />
          : ''
      }
    </div>
  );
}
